import { lazy } from "react";
import { KUNBIS_PROJECT_ID } from "app/config";
import { NAVIGATION_TYPES as PROJECT_NAVIGATION_IDS } from "app/scenes/project/components/main/config";
import { NAVIGATION_TYPES as CAMPAIGN_MANAGE_NAVIGATION_IDS } from "app/scenes/project/components/campaign/manage/config";
import { NAVIGATION_TYPES as USER_NAVIGATION_IDS } from "app/scenes/user/components/config";
import { NAVIGATION_TYPES as SETTINGS_NAVIGATION_IDS } from "app/scenes/settings/components/config";
import CompanyLogin from "app/scenes/companyLogin";
import RedeemGift from "app/scenes/redeemGift";

const Home = lazy(() => import("app/scenes/home/components"));

const Enterprise = lazy(() => import("app/scenes/enterprise"));

const Auth = lazy(() => import("app/scenes/auth"));

const LandingMain = lazy(() => import("app/scenes/landing/main"));
const LandingUser = lazy(() => import("app/scenes/landing/user"));
const LandingProject = lazy(() => import("app/scenes/landing/project"));
const LandingReporter = lazy(() => import("app/scenes/landing/reporter"));
const LandingExpert = lazy(() => import("app/scenes/landing/reporter"));
const LandingExplorer = lazy(() => import("app/scenes/landing/reporter"));
const LandingManifesto = lazy(() => import("app/scenes/landing/manifesto"));

const Legal = lazy(() => import("app/scenes/legal/components"));
const Contact = lazy(() => import("app/scenes/contact/components"));
// const Faq = lazy(() => import('app/scenes/faq'));

const Project = lazy(() => import("app/scenes/project/components/main"));
const ProjectCreate = lazy(() => import("app/scenes/project/components/create"));
const ProjectManage = lazy(() => import("app/scenes/project/components/campaign/create"));

const Projects = lazy(() => import("app/scenes/projects"));
const VotePreview = lazy(() => import("app/scenes/votePreview"));
const Vote = lazy(() => import("app/scenes/vote"));
const StripeVerify = lazy(() => import("app/scenes/stripeVerify"));

const Post = lazy(() => import("app/scenes/post"));

const CampaignManage = lazy(() => import("app/scenes/project/components/campaign/manage"));

const ReporterJoin = lazy(() => import("app/scenes/reporter/components/join"));
const ReporterBecome = lazy(() => import("app/scenes/reporter/components/become"));

const User = lazy(() => import("app/scenes/user/components"));
const Settings = lazy(() => import("app/scenes/settings/components"));

const Checkout = lazy(() => import("app/scenes/checkout"));
const Gift = lazy(() => import("app/scenes/gift/components"));
const Donation = lazy(() => import("app/scenes/donation/components"));
const Subscription = lazy(() => import("app/scenes/subscription"));

const Admin = lazy(() => import("app/scenes/admin/dashboard"));
const AdminCampaignValidate = lazy(() => import("app/scenes/admin/campaignValidate"));
const AdminCampaignView = lazy(() => import("app/scenes/admin/campaignView"));
const AdminStrategy = lazy(() => import("app/scenes/admin/strategy"));

const Testing = lazy(() => import("app/scenes/testing"));

const Error = lazy(() => import("app/scenes/page5xx/components"));

export const ROUTES = {
  HOME: {
    PROPS: {
      path: "/",
      component: Home,
    },
    URL: "/",
  },

  AUTH: {
    PROPS: {
      path: "/connexion",
      component: Auth,
    },
    URL: "/connexion",
  },

  PRIVACY: {
    PROPS: {
      path: "/politique-de-confidentialite",
      component: Legal,
    },
    URL: "/politique-de-confidentialite",
  },

  TERMS: {
    PROPS: {
      path: "/conditions-utilisation",
      component: Legal,
    },
    URL: "/conditions-utilisation",
  },

  LEGAL_NOTICE: {
    PROPS: {
      path: "/mentions-legales",
      component: Legal,
    },
    URL: "/mentions-legales",
  },

  // FAQ: {
  //   PROPS: {
  //     path: '/faq',
  //     component: Faq,
  //   },
  //   URL: '/faq',
  // },

  CONTACT: {
    PROPS: {
      path: "/contact",
      component: Contact,
    },
    URL: "/contact",
  },

  LANDING_MAIN: {
    PROPS: {
      path: "/on-explique-tout",
      component: LandingMain,
    },
    URL: "/on-explique-tout",
  },

  LANDING_USER: {
    PROPS: {
      path: "/on-explique-pour-les-projets",
      component: LandingUser,
    },
    URL: "/on-explique-pour-les-projets",
  },

  LANDING_PROJECT: {
    PROPS: {
      path: "/on-explique-pour-les-utilisateurs",
      component: LandingProject,
    },
    URL: "/on-explique-pour-les-utilisateurs",
  },

  LANDING_REPORTER: {
    PROPS: {
      path: "/associations",
      component: LandingReporter,
    },
    URL: "/associations",
  },

  LANDING_EXPERT: {
    PROPS: {
      path: "/entreprises",
      component: LandingExpert,
    },
    URL: "/entreprises",
  },

  LANDING_EXPLORER: {
    PROPS: {
      path: "/citoyen-ne-s",
      component: LandingExplorer,
    },
    URL: "/citoyen-ne-s",
  },

  LANDING_MANIFESTO: {
    PROPS: {
      path: "/le-manifeste",
      component: LandingManifesto,
    },
    URL: "/le-manifeste",
  },

  POST: {
    PROPS: {
      path: "/publication/:postUuid",
      component: Post,
    },
    URL: (postUuid) => {
      return `/publication/${postUuid}`;
    },
  },

  CAMPAIGN_MANAGE: {
    PROPS: {
      path: "/action/:projectUuid/gerer-le-projet/:campaignId?/:tabId?",
      component: CampaignManage,
      isPrivate: true,
    },
    URL: (projectUuid, props) => {
      const { campaignId, tabId } = props || {};
      const c = campaignId ? `/${campaignId}` : "";
      const t = tabId ? `/${tabId}` : "";

      return `/action/${projectUuid}/gerer-le-projet${c}${t}`;
    },
    URL_PROPS: {
      NAVIGATION_IDS: CAMPAIGN_MANAGE_NAVIGATION_IDS,
    },
  },

  PROJECT_CREATE: {
    PROPS: {
      path: "/ajouter-une-action",
      component: ProjectCreate,
      isPrivate: true,
    },
    URL: "/ajouter-une-action",
  },

  PROJECT_MANAGE: {
    PROPS: {
      path: "/action/:projectUuid/ajouter-une-action/infos/:campaignId?",
      component: ProjectManage,
      isPrivate: true,
    },
    URL: (projectUuid, props) => {
      const { campaignId } = props || {};
      const c = campaignId ? `/${campaignId}` : "";

      return `/action/${projectUuid}/ajouter-une-action/infos${c}`;
    },
  },

  PROJECTS: {
    PROPS: {
      path: "/tous-les-projets",
      component: Projects,
    },
    URL: "/tous-les-projets",
  },

  REPORTER: {
    PROPS: {
      path: "/helper/:projectUuid?",
      component: ReporterJoin,
      isPrivate: true,
    },
    URL: (projectUuid) => {
      const p = projectUuid ? `/${projectUuid}` : "";

      return `/helper${p}`;
    },
  },

  BECOME_REPORTER: {
    PROPS: {
      path: "/devenir-helper/:campaignUuid/:role?",
      component: ReporterBecome,
      isPrivate: true,
    },
    URL: (campaignUuid, props) => {
      const { role } = props;
      const c = campaignUuid ? `/${campaignUuid}` : "";
      const r = role ? `/${role}` : "";

      return `/devenir-helper${c}${r}`;
    },
  },

  USER: {
    PROPS: {
      path: "/user/:tabId?",
      component: User,
      isPrivate: true,
    },
    URL: (tabId) => {
      const t = tabId ? `/${tabId}` : "";

      return `/user${t}`;
    },
    URL_PROPS: {
      NAVIGATION_IDS: USER_NAVIGATION_IDS,
    },
  },

  SETTINGS: {
    PROPS: {
      path: "/parametres/:tabId?",
      component: Settings,
      isPrivate: true,
    },
    URL: (tabId) => {
      const t = tabId ? `/${tabId}` : "";

      return `/parametres${t}`;
    },
    URL_PROPS: {
      NAVIGATION_IDS: SETTINGS_NAVIGATION_IDS,
    },
  },

  CHECKOUT: {
    PROPS: {
      path: "/checkout",
      component: Checkout,
    },
    URL: "/checkout",
  },

  GIFT: {
    PROPS: {
      path: "/le-cadeau",
      component: Gift,
    },
    URL: "/le-cadeau",
  },

  DONATION: {
    PROPS: {
      path: "/don/:projectUuid?",
      component: Donation,
    },
    URL: (projectUuid) => {
      const p = projectUuid ? `/${projectUuid}` : "";

      return `/don${p}`;
    },
  },

  SUBSCRIPTION: {
    PROPS: {
      path: "/abonnement/:subscriptionId",
      component: Subscription,
    },
    URL: (subscriptionId) => {
      return `/abonnement/${subscriptionId}`;
    },
  },

  ADMIN: {
    PROPS: {
      path: "/admin/dashboard",
      component: Admin,
      isPrivate: true,
    },
    URL: "/admin/dashboard",
  },

  ADMIN_CAMPAIGN_VALIDATE: {
    PROPS: {
      path: "/admin/campaign/validate/:projectUuid/:campaignId?",
      component: AdminCampaignValidate,
      isPrivate: true,
    },
    URL: (projectUuid, props) => {
      const { campaignId } = props || {};
      const c = campaignId ? `/${campaignId}` : "/-";

      return `/admin/campaign/validate/${projectUuid}${c}`;
    },
    URL_PROPS: {
      NAVIGATION_IDS: PROJECT_NAVIGATION_IDS,
    },
  },

  ADMIN_CAMPAIGN_VIEW: {
    PROPS: {
      path: "/admin/campaign/view/:id",
      component: AdminCampaignView,
      isPrivate: true,
    },
    URL: (projectId) => {
      return `/admin/campaign/view/${projectId}`;
    },
  },

  ADMIN_STRATEGY: {
    PROPS: {
      path: "/admin/strategy/:campaignUuid?/:strategyId?",
      component: AdminStrategy,
      isPrivate: true,
    },
    URL: (props) => {
      const { campaignUuid, strategyId } = props || {};
      const c = campaignUuid ? `/${campaignUuid}` : "";
      const s = strategyId ? `/${strategyId}` : "";

      return `/admin/strategy/${c}${s}`;
    },
  },

  VOTE: {
    PROPS: {
      path: "/vote/:eventId",
      component: Vote,
      isPrivate: false,
    },
    URL: (eventId) => {
      return `/vote/${eventId}`;
    },
  },

  VOTE_PREVIEW: {
    PROPS: {
      pathname: "a-vos-votes",
      path: "/a-vos-votes/:eventId",
      component: VotePreview,
      isPrivate: true,
    },
    URL: (eventId) => {
      return `/a-vos-votes/${eventId}`;
    },
  },

  STRIPE_VERIFY: {
    PROPS: {
      path: "/stripe/verify/tos/test/test/:projectId?",
      component: StripeVerify,
    },
    URL: (projectId) => {
      return `/stripe/verify/tos/test/test/${projectId}?`;
    },
  },

  PROJECT_BY_NAME: {
    PROPS: {
      path: "/:projectName/:projectId/:campaignName?/:campaignId?/:tabId?",
      component: Project,
    },
    URL: (projectId, props) => {
      const { projectName, campaignName, campaignId } = props || {};

      let tabId = props.tabId;

      if (projectId === KUNBIS_PROJECT_ID) {
        tabId = PROJECT_NAVIGATION_IDS.ABOUT;
      }

      const p = projectName ? `/${projectName}/${projectId}` : `/-/${projectId}`;
      const c = campaignId ? `/${campaignName}/${campaignId}` : "/-/-";
      const t = tabId ? `/${tabId}` : `/${PROJECT_NAVIGATION_IDS.PUBLICATIONS}`;

      return `${p}${c}${t}`;
    },
    URL_PROPS: {
      NAVIGATION_IDS: PROJECT_NAVIGATION_IDS,
    },
  },

  RedeemCoupon: {
    PROPS: {
      path: "/recevoir-un-cadeau",
      component: RedeemGift,
    },
    URL: (code, email) => {
      const payload = [];
      let query = "";

      if (code) {
        payload.push(`code=${code}`);
      }

      if (email) {
        payload.push(`email=${email}`);
      }

      if (payload.length) {
        query = `?${payload.join("&")}`;
      }

      return `/recevoir-un-cadeau${query}`;
    },
  },

  TESTING: {
    PROPS: {
      path: "/testing",
      component: Testing,
      isPrivate: true,
    },
    URL: "/testing",
  },

  500: {
    PROPS: {
      path: "/500",
      component: Error,
    },
    URL: "/500",
  },
};

export const EnterpriseRoute = {
  dashboard: {
    routerProps: {
      pathname: "entreprise",
      path: "/:mainRoute?",
      component: Enterprise,
    },
    getUrl(props) {
      const mainRoute = props?.mainRoute || "";

      return `/${mainRoute}`;
    },
  },

  login: {
    routerProps: {
      pathname: "connexion-entreprise",
      path: "/connexion-entreprise",
      component: CompanyLogin,
    },
    getUrl() {
      return this.routerProps.path;
    },
  },
};

const mapRouteProps = (key) => ROUTES[key].PROPS;
const filterRoutePublic = ({ isPrivate }) => isPrivate !== true;
const filterRoutePrivate = ({ isPrivate }) => isPrivate === true;

export const ROUTES_PUBLIC = Object.keys(ROUTES).map(mapRouteProps).filter(filterRoutePublic);

ROUTES_PUBLIC.push(EnterpriseRoute.login.routerProps);

export const ROUTES_PRIVATE = Object.keys(ROUTES).map(mapRouteProps).filter(filterRoutePrivate);
